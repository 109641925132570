import { TaskPropertyButton } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FieldComponentProps } from "../../types";

type UserPhoneFieldProps = FieldComponentProps<"userPhone">;

export const UserPhoneField: FC<UserPhoneFieldProps> = ({ phone }) => {
  const { t } = useTranslations();

  const isFieldVisible = !!phone;

  if (!isFieldVisible) {
    return null;
  }

  return (
    <TaskPropertyButton>
      {t(
        {
          id: "tasks-page.phone-number-with-value",
          defaultMessage: "Phone number: {phone}",
        },
        { phone }
      )}
    </TaskPropertyButton>
  );
};
