import { Customer, useRestApiProvider } from "@jugl-web/rest-api";
import { useEffect, useMemo, useState } from "react";
import { getCustomerFullName } from "../utils";

interface UseCustomersOptions {
  entityId: string;
  searchQuery?: string;
  skipLoading?: boolean;
}

const EMPTY_ARRAY: Customer[] = [];

export const useCustomers = ({
  entityId,
  searchQuery,
  skipLoading,
}: UseCustomersOptions) => {
  const { customersApi } = useRestApiProvider();

  const [wasSearchingInLastResponse, setWasSearchingInLastResponse] =
    useState(false);

  const {
    data: customersResponse,
    isLoading,
    isFetching,
  } = customersApi.useCustomersListQuery(
    {
      entityId,
      params: {
        // TODO: Add pagination
        page: 1,
        page_size: 100,
        search_term: searchQuery || undefined,
      },
    },
    { skip: skipLoading }
  );

  useEffect(() => {
    if (customersResponse) {
      setWasSearchingInLastResponse(!!searchQuery);
    }
    // We intentionally exclude `searchQuery` from the dependency array because we only want to capture its value
    // when `customersResponse` changes, not whenever `searchQuery` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersResponse]);

  const customers = useMemo(() => {
    if (!customersResponse?.data) return EMPTY_ARRAY;

    return [...customersResponse.data].sort((customerA, customerB) =>
      getCustomerFullName(customerA).localeCompare(
        getCustomerFullName(customerB)
      )
    );
  }, [customersResponse?.data]);

  return {
    customers,
    isLoading,
    isFetching,
    wasSearchingInLastResponse,
  };
};
