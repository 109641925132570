// Local storage keys
export const LANG_KEY = "language";

export const MENTIONS_ALL_ID = "mention-all";
export const TASK_ORDER_EMAIL_ID = "task_order_email";
export const TASK_ORDER_PHONE_ID = "task_order_phone";

// URL's
export const TERMS_OF_USE_URL = "https://jugl.com/terms-of-service";
export const PRIVACY_POLICY_URL = "https://jugl.com/privacy-policy";
