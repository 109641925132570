import { cx } from "@jugl-web/utils";
import { FC, PropsWithChildren } from "react";
import { Tooltip } from "../../Tooltip";

export interface FormGroupProps {
  label: string;
  isInvalid?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  errorMessage?: string;
  classNames?: {
    wrapperClassName?: string;
    labelClassName?: string;
  };
  labelFor?: string;
}

export const FormGroup: FC<PropsWithChildren<FormGroupProps>> = ({
  label,
  isRequired,
  tooltip,
  errorMessage,
  isDisabled,
  labelFor,
  classNames: { labelClassName, wrapperClassName } = {},
  children,
}) => (
  <div className={cx("space-y-2", wrapperClassName)}>
    {label && (
      <Tooltip
        placement="right"
        isDisabled={!tooltip}
        className="font-secondary text-sm"
        renderTrigger={({ props, ref: tooltipRef }) => (
          <label
            ref={tooltipRef}
            htmlFor={labelFor}
            className={cx(
              "font-secondary border-grey-600 text-dark-700 relative ml-[10px] text-[15px] leading-[22px] transition-colors",
              tooltip &&
                " hover:text-primary-900 border-0 border-b-[2px] border-dashed",
              isDisabled && "text-dark-500",
              labelClassName
            )}
            {...props}
          >
            {label}
            {isRequired && (
              <div className="text-gradients-danger absolute top-0 right-[-10px] text-base">
                *
              </div>
            )}
          </label>
        )}
      >
        {tooltip}
      </Tooltip>
    )}
    {children}
    {errorMessage && (
      <div className="text-gradients-danger font-secondary leading-2 text-xs tracking-[0.14px]">
        {errorMessage}
      </div>
    )}
  </div>
);
