import {
  Avatar,
  ConfirmationPopup,
  EmptyListContent,
  phoneStringToInputValue,
  TableGrid,
  Text,
} from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { HeaderBreadcrumbs } from "@jugl-web/ui-components/web";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMarkModuleAsRead } from "@jugl-web/domain-resources/entities/hooks/useMarkModuleAsRead";
import { ModuleNotificationsDialog } from "@jugl-web/domain-resources/module-notifications/components/ModuleNotificationsDialog";
import { useModuleNotificationsUnreadIndicator } from "@jugl-web/domain-resources/module-notifications/hooks/useModuleNotificationsUnreadIndicator";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { Customer } from "@jugl-web/rest-api/customer";
import { Menu } from "@jugl-web/ui-components/cross-platform/Menu";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import {
  convertSnakeCaseToReadableString,
  usePrevious,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Loader from "@web-src/components/Loader";
import { NotificationsButton } from "@web-src/components/NotificationsButton";
import useDebounce from "@web-src/hooks/useDebounce";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import Highlighter from "react-highlight-words";
import { useEffectOnce } from "react-use";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as MenuColumnIcon } from "./assets/menu.svg";
import { ReactComponent as NoCustomersIcon } from "./assets/no-customers.svg";
import { ReactComponent as RemoveIcon } from "./assets/remove-bin.svg";
import { ReactComponent as TaskIcon } from "./assets/task.svg";
import { ReactComponent as ThreeDotsIcon } from "./assets/three-dots.svg";
import {
  customerFieldDescriptorsById,
  OLD_PHONE_NUMBER_FORMAT_REGEXP,
} from "./components/CustomerFormField";
import { CustomizeFieldsDialog } from "./components/CustomizeFieldsDialog/CustomizeFieldsDialog";
import { HoveredComponent } from "./components/HoveredComponent";
import { ManageCustomerDialog } from "./components/ManageCustomerDialog";
// import { ReactComponent as ArchivedTasksIcon } from "./assets/archived.svg";

const CustomersPage: React.FC = () => {
  const { customersApi, customersFormFieldApi } = useRestApiProvider();
  const { navigateToPage } = useNavigation();
  const [isRemoveCustomerModalOpen, setRemoveCustomerModalOpen] =
    useState<boolean>(false);
  const [customerData, setCustomerData] = useState<Customer[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [clientToRemove, setClientToRemove] = useState<string>();
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loadingInProgress, setLoadingInProgress] = useState<boolean>(false);
  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] =
    useState(false);
  const [clientToEdit, setClientToEdit] = useState<string>();
  const [searchQuery, setSearchQuery] = useState<string>();
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const previousDebouncedSearchQuery = usePrevious(debouncedSearchQuery);
  const { entity } = useEntitySelectedProvider();
  const [isCustomizeFieldsOpen, setCustomizeFieldsOpen] =
    useState<boolean>(false);
  const { t } = useTranslations();
  const [deleteCustomer] = customersApi.useDeleteCustomerMutation();
  const { data: formFields } = customersFormFieldApi.useFormFieldsQuery(
    entity?.id ? { entityId: entity.id } : skipToken
  );
  const [loadCustomerList] = customersApi.useLazyCustomersListQuery();
  const handleLoadMoreCustomerData = useCallback(
    async (resetPage?: boolean) => {
      if (loadingInProgress || !entity || (isLastPage && !resetPage)) return;
      setLoadingInProgress(true);
      const response = await loadCustomerList({
        entityId: entity?.id,
        params: {
          page: resetPage ? 1 : page + 1,
          page_size: 20,
          search_term: debouncedSearchQuery || undefined,
        },
      });
      if (response.data?.data) {
        const customerList = response.data?.data;
        const newDataState = [...customerData, ...customerList].filter(
          (customer, index, arr) =>
            arr.findIndex((item) => item.id === customer.id) === index
        );
        setCustomerData(resetPage ? customerList : newDataState);
        setPage((prev) => (resetPage ? 1 : prev + 1));
        const actualPage = resetPage ? 1 : page + 1;
        if (actualPage >= response.data.total_pages) {
          setIsLastPage(true);
        }
      }

      setLoadingInProgress(false);
    },
    [
      customerData,
      debouncedSearchQuery,
      entity,
      isLastPage,
      loadCustomerList,
      loadingInProgress,
      page,
    ]
  );

  const { toast } = useToast({ variant: "web" });

  const getNameAndPosition = useCallback((row: Customer) => {
    const nonCustomFields = row.fields.filter(
      (item) => !item.field.is_cust_field
    );
    const firstName = nonCustomFields.find(
      (item) => item.field.name === "first_name"
    );
    const lastName = nonCustomFields.find(
      (item) => item.field.name === "last_name"
    );
    const position = nonCustomFields.find(
      (item) => item.field.name === "position"
    );

    const fullName = `${firstName?.value || ""} ${lastName?.value || ""}`;
    const positionStr = `${position?.value || "-"}`;
    return { fullName, positionStr };
  }, []);

  const fixLink = (link: string) => {
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      return `https://${link}`;
    }
    return link;
  };
  const handleCopyToClipboard = useCallback(
    (fieldName: string, valueToCopy: string) => {
      window.navigator.clipboard.writeText(valueToCopy);
      toast(
        t(
          {
            id: "feedback.item-was-copied-to-clipboard",
            defaultMessage: "{item} was copied to clipboard",
          },
          {
            item: customerFieldDescriptorsById[fieldName]
              ? t(customerFieldDescriptorsById[fieldName])
              : convertSnakeCaseToReadableString(fieldName),
          }
        )
      );
    },
    [t, toast]
  );
  const columns = useMemo(() => {
    const filteredColumns = formFields?.data?.filter((field) => {
      if (
        field.name === "first_name" ||
        field.name === "last_name" ||
        field.name === "position"
      ) {
        if (field.is_cust_field) {
          return field;
        }
        return null;
      }
      return field;
    });
    const data = filteredColumns
      ?.map((field) => ({
        id: field.id,
        title: customerFieldDescriptorsById[field.name]
          ? t(customerFieldDescriptorsById[field.name])
          : convertSnakeCaseToReadableString(field.name),
        section: field.section,
        pos: field.pos,
        className: "min-w-[200px] border-[1px] border-solid border-[#EEF2F5]",
        content: (row: Customer) => {
          const columnField = row.fields?.find(
            (customerFields) => customerFields.field.id === field.id
          );
          if (columnField?.field?.type === "email" && columnField?.value) {
            return (
              <HoveredComponent
                hoverComponent={
                  <CopyIcon
                    className="absolute right-0 top-1/2 -translate-y-1/2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyToClipboard(
                        field.name,
                        columnField.value || ""
                      );
                    }}
                  />
                }
                className="flex h-full w-full cursor-pointer items-center"
              >
                <span className="w-3/4 overflow-hidden truncate whitespace-nowrap">
                  {columnField.value}
                </span>
              </HoveredComponent>
            );
          }
          if (
            columnField?.field?.type === "mobile" &&
            typeof columnField?.value === "string"
          ) {
            let code;
            let number;
            if (OLD_PHONE_NUMBER_FORMAT_REGEXP.test(columnField.value)) {
              ({ code, phone: number } = phoneStringToInputValue(
                columnField.value
              ));
            } else {
              [, code, number] = columnField.value.split(",");
            }
            const phoneNumber = `(${code?.replace("+", "")}) ${number}`;
            if (code && number) {
              return (
                <HoveredComponent
                  hoverComponent={
                    <CopyIcon
                      className="absolute right-0 top-1/2 -translate-y-1/2"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopyToClipboard(
                          columnField.field.name,
                          phoneNumber
                        );
                      }}
                    />
                  }
                  className="flex h-full w-full cursor-pointer items-center"
                >
                  <Highlighter
                    autoEscape
                    highlightClassName="bg-primary-200"
                    searchWords={[searchQuery || ""]}
                    textToHighlight={phoneNumber}
                  />
                </HoveredComponent>
              );
            }
          }
          if (columnField?.field.type === "url" && columnField?.value) {
            return (
              <HoveredComponent
                hoverComponent={
                  <CopyIcon
                    className="absolute right-0 top-1/2 -translate-y-1/2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyToClipboard(
                        field.name,
                        columnField.value || ""
                      );
                    }}
                  />
                }
                className="h-full w-full cursor-pointer"
              >
                <a
                  href={fixLink(columnField?.value)}
                  className="text-primary flex h-full items-center truncate whitespace-nowrap"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Highlighter
                    autoEscape
                    highlightClassName="bg-primary-200"
                    searchWords={[searchQuery || ""]}
                    textToHighlight={columnField?.value}
                  />
                </a>
              </HoveredComponent>
            );
          }
          return (
            <Text
              variant="body3"
              className="text-dark overflow-hidden truncate whitespace-nowrap"
              title={columnField?.value || "--"}
            >
              {columnField?.value || "--"}
            </Text>
          );
        },
        grow: true,
      }))
      .sort((a, b) => {
        const sectionComparison = b.section.localeCompare(a.section);

        if (sectionComparison === 0) {
          return a.pos - b.pos;
        }

        return sectionComparison;
      });
    return (
      (Array.isArray(data) &&
        [
          {
            id: "menu",
            title: (
              <MenuColumnIcon
                className="cursor-pointer"
                onClick={() => {
                  setCustomizeFieldsOpen(true);
                }}
              />
            ),
            headerClassName: "rounded-tl-xl",
            className:
              "p-0 items-center border-y-[1px] border-x-[1px] border-solid border-[#EEF2F5] font-secondary font-medium text-base",
            grow: false,
            content: ({ id }: Customer) => (
              <Menu
                placement="bottom"
                autoClose
                sections={[
                  [
                    {
                      id: "1",
                      label: t({
                        id: "common.edit",
                        defaultMessage: "Edit",
                      }),
                      icon: <EditIcon />,
                      onSelect: () => {
                        setClientToEdit(id);
                        setSidebarOpen(true);
                      },
                    },
                    {
                      id: "2",
                      label: t({
                        id: "common.delete",
                        defaultMessage: "Delete",
                      }),
                      icon: <RemoveIcon />,
                      onSelect: () => {
                        setClientToRemove(id);
                        setRemoveCustomerModalOpen(true);
                      },
                    },
                  ],
                ]}
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger
                    ref={triggerRef}
                    className="h-[80px] w-[52px] cursor-pointer rounded-lg border-0 bg-transparent p-0"
                  >
                    <ThreeDotsIcon className="cursor-pointer" />
                  </Trigger>
                )}
              />
            ),
          },
          {
            id: "name_position",
            title: t({
              id: "customers-page.name-and-position",
              defaultMessage: "Name & Positition",
            }),
            className:
              "border-[1px] min-w-[300px] border-solid border-[#EEF2F5]",
            grow: true,
            content: (row: Customer) => {
              const nameAndPosition = getNameAndPosition(row);

              return (
                <div className="flex flex-row items-center justify-start gap-2.5">
                  <div className="w-[32px]">
                    <Avatar
                      size="lg"
                      username={nameAndPosition.fullName}
                      imageUrl={row.logo}
                      className="h-[32px] w-[32px]"
                    />
                  </div>
                  <div className="flex w-3/4 flex-col">
                    {" "}
                    <Text
                      variant="body2"
                      className="overflow-hidden truncate whitespace-nowrap font-medium"
                      title={nameAndPosition?.fullName}
                    >
                      <Highlighter
                        autoEscape
                        highlightClassName="bg-primary-200"
                        searchWords={[searchQuery || ""]}
                        textToHighlight={nameAndPosition?.fullName}
                      />
                    </Text>
                    <Text
                      variant="body3"
                      className="text-grey-800 overflow-hidden truncate whitespace-nowrap text-sm"
                      title={nameAndPosition?.positionStr}
                    >
                      <Highlighter
                        autoEscape
                        highlightClassName="bg-primary-200"
                        searchWords={[searchQuery || ""]}
                        textToHighlight={nameAndPosition?.positionStr}
                      />
                    </Text>
                  </div>
                </div>
              );
            },
          },
          {
            id: "associated-tasks",
            title: t({
              id: "customers-page.associated-tasks",
              defaultMessage: "Associated Tasks",
            }),
            className:
              "border-[1px] min-w-[240px] border-solid border-[#EEF2F5] cursor-pointer px-0",
            grow: true,
            content: (row: Customer) => (
              <div
                className="flex h-full w-full flex-row items-center justify-start gap-4 px-4"
                onClick={() => {
                  navigateToPage("customersTasks", { customerId: row.id });
                }}
              >
                <div className="flex flex-col">
                  <Text
                    variant="body2"
                    className="flex items-center gap-2 text-sm font-medium"
                  >
                    <TaskIcon />
                    {row.active_task || 0}{" "}
                    {t({
                      id: "common.active",
                      defaultMessage: "Active",
                    })}
                  </Text>
                  <Text variant="body3" className="text-grey-800 mt-1 text-sm">
                    {row.completed_task || 0}{" "}
                    {t({
                      id: "common.completed",
                      defaultMessage: "Completed",
                    })}
                  </Text>
                </div>
              </div>
            ),
          },
          // {
          //   id: "archived-tasks",
          //   title: t({
          //     id: "customers-page.archived-tasks",
          //     defaultMessage: "Archived Tasks",
          //   }),
          //   className: "border-[1px] w-[240px] border-solid border-[#EEF2F5]",
          //   grow: true,
          //   content: () => (
          //     <div className="flex flex-row items-center justify-start gap-2">
          //       <ArchivedTasksIcon />
          //       <Text variant="body3" className="text-dark-800 font-medium">
          //         0{" "}
          //         {t({
          //           id: "common.tasks",
          //           defaultMessage: "Tasks",
          //         })}
          //       </Text>
          //     </div>
          //   ),
          // },
        ].concat(data)) ||
      []
    );
  }, [
    navigateToPage,
    formFields?.data,
    getNameAndPosition,
    handleCopyToClipboard,
    searchQuery,
    t,
  ]);

  const handleDeleteCustomer = async (customerId: string) => {
    const entityId = entity?.id || "";
    const response = await deleteCustomer({ entityId, customerId });
    if ("data" in response) {
      toast(
        t({
          id: "feedback.deleted-customer",
          defaultMessage: "Client has been deleted",
        })
      );
      setCustomerData((prev) =>
        prev.filter((customer) => customer.id !== customerId)
      );
    }
  };

  const isEmpty =
    !loadingInProgress && customerData.length === 0 && !searchQuery;
  const getCustomerName = (customerId: string) => {
    const customerData2 = customerData.find(
      (customer) => customer.id === customerId
    );
    const clientName = customerData2?.fields.reduce((accumulator, item) => {
      const {
        field: { section, name },
      } = item;
      if (
        section === "personal" &&
        (name === "first_name" || name === "last_name")
      ) {
        const val = item.value as string;
        accumulator += ` ${val || ""}`;
      }
      return accumulator;
    }, "");
    return clientName;
  };

  const {
    isUnread: hasUnreadNotifications,
    markAsRead: markNotificationsAsRead,
  } = useModuleNotificationsUnreadIndicator({
    entityId: entity?.id,
    module: "customer",
  });

  useMarkModuleAsRead({ entityId: entity.id, module: "customer" });
  useEffectOnce(() => {
    handleLoadMoreCustomerData(true);
  });
  useEffect(() => {
    if (debouncedSearchQuery !== previousDebouncedSearchQuery) {
      setCustomerData([]);
      setIsLastPage(false);
      handleLoadMoreCustomerData(true);
    }
  }, [
    debouncedSearchQuery,
    handleLoadMoreCustomerData,
    previousDebouncedSearchQuery,
  ]);
  const onCustomerAdded = (data: Customer, isEdit?: boolean) => {
    if (!isEdit) {
      const shouldBeInSearchResults =
        debouncedSearchQuery &&
        data.fields.some((field) => {
          const { value } = field;
          return value
            ?.toString()
            .toLowerCase()
            .includes(debouncedSearchQuery.toLowerCase());
        });
      if (shouldBeInSearchResults || !debouncedSearchQuery) {
        setCustomerData((prev) => [...prev, data]);
      }
    }
    if (isEdit) {
      setCustomerData((prev) =>
        prev.map((customer) => {
          if (customer.id === data.id) {
            return data;
          }
          return customer;
        })
      );
    }
  };
  return (
    <div className="flex h-full">
      <ConfirmationPopup
        variant="web"
        title={t({
          id: "customers-page.delete-customer",
          defaultMessage: "Delete Customer",
        })}
        isOpen={isRemoveCustomerModalOpen}
        hasCancelButton
        message={
          <div className="flex flex-col items-center ">
            <Text variant="body3" className="text-grey">
              {t(
                {
                  id: "customers-page.customer-delete-confirmation",
                  defaultMessage: "Are you sure you want to delete {customer}?",
                },
                {
                  customer: getCustomerName(clientToRemove || ""),
                }
              )}
            </Text>
          </div>
        }
        buttons={[
          {
            label: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            onClick: () => {
              if (clientToRemove) {
                handleDeleteCustomer(clientToRemove);
              }
              setRemoveCustomerModalOpen(false);
            },
          },
        ]}
        onRequestClose={() => {
          setRemoveCustomerModalOpen(false);
          setClientToRemove(undefined);
        }}
      />
      <ManageCustomerDialog
        isOpen={isSidebarOpen}
        onClose={() => {
          setSidebarOpen(false);
          setClientToEdit(undefined);
        }}
        customerId={clientToEdit}
        onSuccess={onCustomerAdded}
      />
      <CustomizeFieldsDialog
        isOpen={isCustomizeFieldsOpen}
        onClose={() => {
          setCustomizeFieldsOpen(false);
        }}
      />
      <div className="flex h-full w-full flex-auto flex-col">
        <HeaderBreadcrumbs
          className="shrink-0"
          items={[
            {
              title: t({
                id: "customers-page.customers",
                defaultMessage: "Customers",
              }),
            },
          ]}
          rightContent={
            <>
              <NotificationsButton
                hasUnreadIndicator={hasUnreadNotifications}
                onClick={() => {
                  setIsNotificationsDialogOpen(true);
                  markNotificationsAsRead();
                }}
              />
              {entity && (
                <ModuleNotificationsDialog
                  isOpen={isNotificationsDialogOpen}
                  entityId={entity.id}
                  module="customer"
                  onNotificationClick={() =>
                    setIsNotificationsDialogOpen(false)
                  }
                  onClose={() => setIsNotificationsDialogOpen(false)}
                />
              )}
            </>
          }
        />
        <div className="bg-grey-200 flex h-full min-h-[calc(100vh-60px)] flex-col items-center justify-start">
          {!isEmpty && (
            <div className="mt-11 mb-8 flex h-[40px] w-full justify-between px-8">
              <div className="flex h-[40px] items-center rounded-lg bg-white">
                <SearchInput
                  className="my-2 h-[40px] w-[343px]"
                  variant="blank"
                  onChange={(e) => setSearchQuery(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-row gap-4">
                <Button
                  className="h-[40px]"
                  onClick={() => setSidebarOpen(true)}
                >
                  {t({
                    id: "customers-page.add-customer",
                    defaultMessage: "Add Customer",
                  })}{" "}
                  <AddIcon />
                </Button>
              </div>
            </div>
          )}
          {isEmpty ? (
            <div className="h-[calc(100vh-176px)]">
              <EmptyListContent
                type="custom"
                customButton={{
                  text: t({
                    id: "customers-page.add-new-customer",
                    defaultMessage: "Add New Customer",
                  }),
                  onClick: () => setSidebarOpen(true),
                }}
                customTitle={t({
                  id: "customers-page.no-customers",
                  defaultMessage: "No Customers",
                })}
                customSubtitle={t({
                  id: "customers-page.no-customers-yet",
                  defaultMessage:
                    "Looks like you haven’t added any Customer yet",
                })}
                customImg={<NoCustomersIcon />}
              />
            </div>
          ) : (
            <div className="h-full w-full flex-grow overflow-x-auto pl-8">
              <TableGrid
                emptyContent={(() => {
                  if (loadingInProgress) {
                    return <Loader />;
                  }

                  return (
                    <>
                      {t({
                        id: "customers-page.no-results",
                        defaultMessage: "No results found",
                      })}
                    </>
                  );
                })()}
                onReachEnd={() =>
                  !isLastPage &&
                  !loadingInProgress &&
                  handleLoadMoreCustomerData()
                }
                unstyled
                columns={columns}
                data={customerData || []}
                headerCellClassName="px-4 py-[11.5px] border-t-0 border-b-0 border-x-[1px] h-[40px] border-solid border-[#EEF2F5] text-xs text-dark-700 font-normal bg-white flex items-center"
                cellClassName="whitespace-nowrap overflow-hidden truncate hover:bg-grey-100 hover:border-[1px] hover:border-grey-400 px-4 border-t-0 border-b-0 h-[80px] text-dark-800 font-normal flex items-center text-sm bg-white"
                rowHoverClassName="grey-100-bg"
                className="rounded-xl border-x-0 border-t-[1px] border-b-0 border-solid border-[#EEF2F5]"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomersPage;
