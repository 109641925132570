import { InteractiveContainer, Menu } from "@jugl-web/ui-components";
import React from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { useTaskCustomFieldsDetails } from "@jugl-web/domain-resources/tasks/hooks/useTaskCustomFieldsDetails";
import {
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
} from "@jugl-web/utils/consts";
import { ReactComponent as ChevronDownIcon } from "./assets/chevron-down.svg";

export const MandatoryContactFieldSelector: React.FC<{
  selectedContactFieldId:
    | typeof TASK_ORDER_EMAIL_ID
    | typeof TASK_ORDER_PHONE_ID;
  className?: string;
  onChange: (
    selectedField: typeof TASK_ORDER_EMAIL_ID | typeof TASK_ORDER_PHONE_ID
  ) => void;
}> = ({ selectedContactFieldId, className, onChange }) => {
  const { t } = useTranslations();
  const { customFieldTypeToDetails } = useTaskCustomFieldsDetails();

  const type =
    selectedContactFieldId === TASK_ORDER_EMAIL_ID ? "text" : "number";
  return (
    <>
      <Menu
        placement="bottom-end"
        sections={[
          [
            {
              id: "email",
              label: t({
                id: "common.email",
                defaultMessage: "Email",
              }),
              toggle: "radio",
              togglePlacement: "start",
              isSelected: selectedContactFieldId === TASK_ORDER_EMAIL_ID,
              onSelect: (_, close) => {
                onChange(TASK_ORDER_EMAIL_ID);
                close();
              },
            },
            {
              id: "phone",
              label: t({
                id: "common.phone-number",
                defaultMessage: "Phone number",
              }),
              toggle: "radio",
              togglePlacement: "start",
              isSelected: selectedContactFieldId === TASK_ORDER_PHONE_ID,
              onSelect: (_, close) => {
                onChange(TASK_ORDER_PHONE_ID);
                close();
              },
            },
          ],
        ]}
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            as={InteractiveContainer}
            ref={triggerRef}
            className={cx(
              "bg-grey-100 hover:bg-grey-200 group relative flex w-full min-w-[248px] items-center justify-between gap-2 rounded-lg p-3 transition-colors",
              className
            )}
          >
            <div className="flex min-w-0 items-center gap-3">
              {React.createElement(
                customFieldTypeToDetails[type].secondaryIcon,
                {
                  className: "shrink-0",
                }
              )}
              <div className="flex flex-col truncate">
                <span className="text-dark font-secondary truncate text-xs leading-[140%]">
                  {t(
                    selectedContactFieldId === "task_order_email"
                      ? {
                          id: "common.email",
                          defaultMessage: "Email",
                        }
                      : {
                          id: "common.phone-number",
                          defaultMessage: "Phone number",
                        }
                  )}
                </span>
                <span className="font-secondary text-[10px] leading-[140%] text-[#828282]">
                  {t({
                    id: "order-form-create-page.mandatory-contact-field-selector-label",
                    defaultMessage:
                      "Email or Phone number is mandatory for each Form",
                  })}
                  <span className="text-gradients-danger"> *</span>
                </span>
              </div>
            </div>
            <ChevronDownIcon className={isOpen ? "rotate-180" : ""} />
          </Trigger>
        )}
      />
    </>
  );
};
